import moment from "moment";

import {http} from "@/services";
import {toast} from '@/mixins/toast'
import {linkHelpers} from "./linkHelpers";
import {options} from "./options";
import {clone} from "@/mixins/clone";

export const helpers = {
    mixins: [linkHelpers, options, toast, clone],
    watch: {
        showNewClient: {
            immediate: true,
            deep: false,
            handler(value) {
                if (value) this.initialSteps = 3
                else this.initialSteps = 2
            }
        }
    },
    data() {
        return {
            startDate: null,
            selected: null,
            inProgress: true,
            initEventInProgress: false,
            changeEvent: true,
            weekIdx: 0,
            storeStarDates: [],
            booking: [],
            errors: [],
            emptyForm: null,
            role: null,
            initialSteps: 2,
            appointmentsRequested: {
                startTime: null,
                host: "",
            },
            form: {
                startDate: null,
                startTime: null,
                endTime: null,
                singleCouple: null,
                submited: false,
                lead_source: null,
                owner: null,

                location: null,
                type: null,
                transactionType: null,
                description: null,
                short: null,
                duration: null,
                ids: null,
                names: null,
                eventKey: null,

                client1: {
                    title: null,
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    sex: "",
                },
                client2: {
                    title: null,
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    sex: "",
                },
                access_code: null
            },
            hostDetails: null,
            show: {
                form: true,
                error: false,
                errorMessage: false,
                confirmation: this.showConfirmationPanel
            },
            loading: false,
            clientFormErrors: {
                client1: {
                    title: null,
                    firstName: null,
                    middleName: null,
                    lastName: null,
                    phoneNumber: null,
                    email: null,
                    sex: null,
                },
                client2: {
                    title: null,
                    firstName: null,
                    lastName: null,
                    phoneNumber: null,
                    email: null,
                    sex: null,
                },
            },
        }
    },
    async created() {
        this.emptyForm = JSON.parse(JSON.stringify(this.form));
        await this.initEvent();
    },
    computed: {
        showClientForm() {
            return (this.showNewClient && !this.form.ids)
        },
        step() {
            return (this.form.eventKey && this.initialSteps > 2 ? (this.initialSteps - 1) : this.initialSteps);
        },
        subject() {
            if (this.form.type) {
                return this.form.type.split(" ").join("+");
            }
            return "";
        }
    },
    methods: {
        async getSlots() {
            // get available appointment slots

            // init variables
            let host = null
            this.inProgress = true;

            // guards
            // get optional host details (required for host id)

            /* (CB) removing route condition because route does not contain host info!
              if (!this.hostDetails && this.$route.params.host) await this.getHostDetails()
             */
            if (!this.hostDetails) await this.getHostDetails()

            host = this.hostDetails ? this.hostDetails.id : null
            if (!this.startDate) return this.inProgress = false;

            // start
            let params = {
                host: host,
                role: this.form.role,
                appointment_type: this.form.type,
                start_date: this.startDate.format("YYYY-MM-D"),
            }
            this.get(params)
        },
        setEventDetails(details) {
            // set event details from server

            // set location based upon location prop or event details from server
            let location = 'Telephone'
            if (details.location) {
                location = details.location
            }
            if (this.location) {
                location = this.location
            }

            this.form.duration = details.duration;
            this.form.transactionType = details.transaction
            this.form.description = details.description
            this.form.short = details.short
            this.form.location = location
            this.form.additional_form = details.additional_form
            this.form.title += ` - ${this.form.short ? (this.form.short + ' - ') : ''}${this.form.location}`;
            // location override from params
            if (this.$route.query.location && this.locationOptions.includes(this.$route.query.location)) {
                this.form.location = this.$route.query.location
            }

            // lead-source override from params
            if (this.$route.query.source) {
                this.form.lead_source = this.$route.query.source
            }
        },
        setRole(role) {
            this.role = role;
            this.getSlots();
        },
        setTransaction() {
            // set client ids to event
            if (this.transaction) {
                this.form.ids = this.transaction.clients.map((c) => c.id).join(",");
                this.form.names = this.transaction.clients
                    .map((c) => c.profileNameLast)
                    .join(",");
            }
        },
        async initEvent() {
            // get existing event details
            this.initEventInProgress = true;
            if (this.form.eventKey) {
                let params = {
                    event_key: this.form.eventKey
                }
                http
                    .get('event_detail', {params: params})
                    .then((response) => {
                        this.form = response.data
                        this.initEventInProgress = false;
                        this.form.submited = true;
                    })
                    .catch((e) => {
                        this.errors.push(e);
                        this.initEventInProgress = false;
                    });
            }
            return true
        },
        prevMonth() {
            this.weekIdx = this.weekIdx - 1;
            this.startDate = this.storeStarDates[this.weekIdx];
            this.getSlots();
        },
        nextMonth() {
            this.storeStarDates[this.weekIdx] = this.startDate;
            this.weekIdx = this.weekIdx + 1;
            this.startDate = moment(this.startDate).add(1, 'M').startOf("month");
            this.getSlots();
        },
        currentMonth() {
            this.weekIdx = 0;
            this.startDate = this.storeStarDates[0];
            this.getSlots();
        },
        slots(day) {
            if (day) {
                return day.timeslots.map(function (e) {
                    return e.time;
                });
            }
        },
        onReset(event) {
            event.preventDefault();
            // Reset form values
            this.form = JSON.parse(JSON.stringify(this.emptyForm));
        },
        setSelected({day, time, index, selectedEmployeeIndex = null}) {
            console.log('set selected')

            this.form.startDate = moment(day.date).format("YYYY-MM-DD");
            this.form.startTime = time;
            this.form.endTime = moment(this.form.startTime,
                'HH:mm').add(this.form.duration,
                'minutes').format('HH:mm');
            console.log('here 1')

            if (selectedEmployeeIndex === null) {
                // randomise the host in the employees available (if anyone selected)
                selectedEmployeeIndex = Math.floor(Math.random() * day.timeslots[index].employees.length)
            }
            console.log('here 2')

            this.form.owner = day.timeslots[index].employees[selectedEmployeeIndex].id;
            this.form.title = day.timeslots[index].employees[selectedEmployeeIndex].name.split(' ').map(function (e) {
                return e[0];
            }).join('');
            console.log('here 3')

            this.form.title += ` - ${this.form.short ? (this.form.short + ' - ') : ''}${this.form.location}`;
            this.form.submited = false;

            console.log('save initial steps', this.initialSteps)
            if (this.form.eventKey) {
                this.rescheduleEvent()
            } else if (this.initialSteps === 2 || (this.form.ids && this.form.names)) {
                console.log('post')
                let data = this.clone(this.form)

                if (this.form.ids) {
                    delete data.client1;
                    delete data.singleCouple;
                    if (data.client2) {
                        delete data.client2;
                    }
                }
                http
                    .post('save_booking/', data)
                    .then((response) => {
                        console.log('save booking done', response.data, response.status)

                        this.toast('Appointment confirmed')
                        console.log('appointment confirmed emit')

                        this.$emit('confirmed', response.data)
                        this.form.eventKey = response.data.eventKey;
                        this.form.submited = true;


                    })
                    .catch((e) => {
                        this.errors.push(e);
                        this.handleErrors(e.response.data)
                    });
            }
            console.log('else nothing')
        },
        handleErrors(error) {
            this.form.submited = false;
            this.form.startDate = null
            this.form.startTime = null
            this.form.endTime = null
            this.$refs.calendarMonth.clear()
            if (typeof error === 'object' && 'action' in error) {
                if (error.action === 'BLOCKED') {
                    this.show.error = true
                    this.show.errorMessage = error.message
                } else if (error.action === 'BOOKED') {
                    // if double booked then refresh event slots
                    this.toastError(error.message)
                    this.getSlots()
                }
            }
        },
        clearClientFormErrors() {
            this.clientFormErrors.client1.title = null;
            this.clientFormErrors.client1.firstName = null;
            this.clientFormErrors.client1.middleName = null;
            this.clientFormErrors.client1.lastName = null;
            this.clientFormErrors.client1.phoneNumber = null;
            this.clientFormErrors.client1.email = null;
            this.clientFormErrors.client1.sex = null;
            this.clientFormErrors.client2.title = null;
            this.clientFormErrors.client2.firstName = null;
            this.clientFormErrors.client2.lastName = null;
            this.clientFormErrors.client2.phoneNumber = null;
            this.clientFormErrors.client2.email = null;
            this.clientFormErrors.client2.sex = null;
        },
        checkForm() {
            this.clearClientFormErrors();

            let count = 0;
            if (!this.form.singleCouple) {
                if (!this.form.client1.firstName.trim()) {
                    this.clientFormErrors.client1.firstName = "First name is required";
                    count += 1;
                }
                if (!this.form.client1.lastName.trim()) {
                    this.clientFormErrors.client1.lastName = "Last name is required";
                    count += 1;
                }
                if (!this.form.client1.phoneNumber.trim()) {
                    this.clientFormErrors.client1.phoneNumber = "Phone number is required";
                    count += 1;
                }
                if (!this.form.client1.email.trim()) {
                    this.clientFormErrors.client1.email = "Email address is required";
                    count += 1;
                }
                if (!this.form.client1.title) {
                    this.clientFormErrors.client1.title = "Title is required";
                    count += 1;
                }
            } else {
                if (!this.form.client1.firstName.trim()) {
                    this.clientFormErrors.client1.firstName = "First name is required";
                    count += 1;
                }
                if (!this.form.client1.lastName.trim()) {
                    this.clientFormErrors.client1.lastName = "Last name is required";
                    count += 1;
                }
                if (!this.form.client1.title) {
                    this.clientFormErrors.client1.title = "Title is required";
                    count += 1;
                }
                if (!this.form.client2.firstName.trim()) {
                    this.clientFormErrors.client2.firstName = "First name is required";
                    count += 1;
                }
                if (!this.form.client2.lastName.trim()) {
                    this.clientFormErrors.client2.lastName = "Last name is required";
                    count += 1;
                }
                if (!this.form.client2.title) {
                    this.clientFormErrors.client2.title = "Title is required";
                    count += 1;
                }


                if (!this.form.client1.phoneNumber.trim() && !this.form.client1.email.trim()) {
                    this.clientFormErrors.client1.email =
                        "Email or phone number is required";
                    this.clientFormErrors.client1.phoneNumber =
                        "Email or phone number is required";
                    count += 1;
                }
                if (!this.form.client2.phoneNumber.trim() && !this.form.client2.email.trim()) {
                    this.clientFormErrors.client2.email =
                        "Email or phone number is required";
                    this.clientFormErrors.client2.phoneNumber =
                        "Email or phone number is required";
                    count += 1;
                }

                if (this.form.client1.email.trim() && this.form.client2.email.trim() && this.form.client1.email === this.form.client2.email) {
                    this.clientFormErrors.client1.email =
                        "Email address cannot be the same for both";
                    this.clientFormErrors.client2.email =
                        "Email address cannot be the same for both";
                    count += 1;
                }
            }
            return count;
        },
        checkForErrors() {
            // checks client form for error and additional forms
            if (!this.checkForm()) {
                if (!this.form.additional_form) {
                    this.onSubmit()
                } else {
                    this.$bvModal.show('additional-form-modal');
                }
            }
        },
        onSubmit() {
            // saves form when click from client forms
            if (!this.checkForm()) {
                this.loading = true
                if (this.form.client1.title) {
                    if (
                        this.form.client1.title === "Mr" ||
                        this.form.client1.title === "Master"
                    ) {
                        this.form.client1.sex = "male";
                    } else {
                        this.form.client1.sex = "female";
                    }
                }
                if (this.form.client2.title) {
                    if (
                        this.form.client2.title === "Mr" ||
                        this.form.client2.title === "Master"
                    ) {
                        this.form.client2.sex = "male";
                    } else {
                        this.form.client2.sex = "female";
                    }
                }
                let data = this.clone(this.form)
                if (!data.client2.title) {
                    delete data.client2;
                }
                console.log('save booking', data)

                http
                    .post(`save_booking/`, data)
                    .then((response) => {
                        this.$emit('confirmed', response.data)

                        this.loading = false
                        this.form.submited = true;
                        this.form.eventKey = response.data.eventKey;
                        this.form.hostName = response.data.hostName;

                    })
                    .catch((e) => {
                        this.loading = false
                        this.errors.push(e);
                        this.handleErrors(e.response.data)

                    });
            }
        },

        rescheduleEvent() {
            let data = this.clone(this.form)
            delete data.client1;
            delete data.singleCouple;
            if (data.client2) delete data.client2;

            http.post(`rescheduledevent/`, data).then((response) => {
                this.toast('Appointment rescheduled')
                this.form.submited = true;
                this.$emit('confirmed', response.data)

                let params = {eventKey: response.data.eventKey};
                if (this.form.lead_source) {
                    params.leadSource = this.form.lead_source;
                }
            }).catch((e) => {
                this.errors.push(e);
                this.handleErrors(e.response.data)
            });
        },
        navToEvent() {
            let params = {eventKey: this.form.eventKey};
            if (this.form.type) {
                params.type = this.form.type.split(' ').join('-');
            }
            if (this.form.ids) {
                params.ids = this.form.ids;
            }
            if (this.form.names) {
                params.names = this.form.names.split(' ').join('-');
            }
            if (this.form.role) {
                params.role = this.form.role.split(' ').join('-');
            }
            if (this.form.lead_source) {
                params.leadSource = this.form.lead_source;
            }
            if (this.appointmentsRequested.host) {
                params.host = this.appointmentsRequested.host.replaceAll(' ', '-')
            }
            this.$router.push({
                name: this.appointmentsRequested.host ? 'bookingHost' : 'bookingRole',
                params: params,
            }).catch((e) => console.log(e));
        },
        get(params) {
            http.get('booking/', {params: params}).then((response) => {
                this.inProgress = false;

                let data = []
                // set event details for frontend display to user
                this.setEventDetails(response.data.event_details)
                // convert response for calender options
                let dateKeys = Object.keys(response.data.slots)

                // create day objects with timeslots and day attribute
                for (let key in dateKeys) {
                    let timeSlots = []
                    let timeKeys = Object.keys(response.data.slots[dateKeys[key]])
                    // create time slot objects with time and employees
                    timeKeys = timeKeys.sort(
                        (a, b) => Number(a.replace(':', '')) - Number(b.replace(':', ''))
                    )
                    for (let t in timeKeys) {
                        let employees = []
                        let employeeKeys = Object.keys(response.data.slots[dateKeys[key]][timeKeys[t]])
                        // create employee object with id and name
                        for (let e in employeeKeys) {
                            employees.push({
                                id: employeeKeys[e],
                                name: response.data.slots[dateKeys[key]][timeKeys[t]][employeeKeys[e]]
                            })
                        }
                        timeSlots.push({
                            time: timeKeys[t],
                            employees: employees
                        })
                    }
                    data.push({
                        date: `${dateKeys[key]}T00:00:00`,
                        timeslots: timeSlots
                    })
                }
                this.booking = data

            }).catch((e) => {
                this.errors.push(e);
                this.inProgress = false;
            });
        },
        async getHostDetails() {
            console.log('get host details', this.appointmentsRequested.host)
            if (!this.appointmentsRequested.host) return
            // init
            let params = {
                name: this.appointmentsRequested.host.replaceAll('-', ' ')
            }
            // guards
            if (!params.name) return
            // fetch
            return http.get('booking_host_detail', {params: params}).then(
                response => {
                    this.hostDetails = response.data
                    return true
                }
            ).catch(
                error => {
                    console.log(error)
                    return false
                }
            )
        },

    }
};
