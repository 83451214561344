var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeslots"},[(_vm.selectedBooking && _vm.selectedBooking.timeslots.length)?_c('div',{staticClass:"d-flex flex-row pt-2"},[_c('div',{staticClass:"timeSelectorWrapper mr-1 w-50 ml-2"},_vm._l((_vm.selectedBooking.timeslots),function(option,index){return _c('div',{key:index,staticClass:"d-flex flex-row"},[_c('div',{staticClass:"timeSelector",class:{ 'selected-timeslot': _vm.timeslotSelected === option.time },attrs:{"id":`popover` + index},on:{"click":function($event){_vm.timeslotSelected = option.time}}},[_vm._v(" "+_vm._s(_vm.addAMPM(option.time))+" ")]),_c('b-popover',{attrs:{"target":`popover` + index,"delay":"500","placement":"top","triggers":"hover focus"}},_vm._l((option.employees),function(p,person_index){return _c('span',{key:person_index},[_c('b-badge',{staticClass:"mr-1",attrs:{"href":"#","variant":"success"},on:{"click":function($event){return _vm.$emit('setSelected', {
                  x: _vm.selectedBooking,
                  y: option.time,
                  z: index,
                  q: person_index,
                })}}},[_vm._v(" "+_vm._s(p.name)+" "),_c('b-icon',{attrs:{"icon":"check"}})],1)],1)}),0),_c('div',{staticClass:"timeConfirm",class:{ 'show-confirm': option.time === _vm.timeslotSelected },on:{"click":function($event){return _vm.$emit('setSelected', {
              x: _vm.selectedBooking,
              y: option.time,
              z: index,
            })}}},[_vm._v(" Confirm ")])],1)}),0),_c('div',{staticClass:"w-50"},[_c('h5',{staticClass:"d-block w-100 pl-3 pb-2 pt-3"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.dateSelected,"dddd, MMMM Do"))+" ")]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"mb-2"},[_vm._v(" Type: "),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.kind))])]),_c('div',{staticClass:"mb-2"},[_vm._v(" Duration: "),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.durationInMinutes - 10)+" - "+_vm._s(_vm.durationInMinutes + 10)+" Minutes")])]),(_vm.timeslotSelected)?_c('div',{staticClass:"mb-2"},[_vm._v(" Start Time: "),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.addAMPM(_vm.timeslotSelected)))])]):_vm._e()])])]):_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('h5',{staticClass:"d-block text-center pb-3 pt-1"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.dateSelected,"dddd, MMMM Do"))+" ")]),_c('div',{staticClass:"timeSelector w-75 pt-3 pb-3"},[_vm._v("No Appointments")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }