<template>
  <span>
    <b-form-group v-if="show_primary" v-slot="{ ariaDescribedby }"
                  class="radio-type-buttons">
      <b-form-radio v-for="(option, index) in optionsAction"
                    :key="'optionsMode' + index"
                    v-model="form.type"
                    :aria-describedby="ariaDescribedby"
                    :value="option.value">
        <span v-dompurify-html="option.text" />
      </b-form-radio>
    </b-form-group>

    <b-form-group v-if="show_secondary" style="pointer-events:none" v-slot="{ ariaDescribedby }"
                  class="radio-type-buttons">
      <b-form-radio v-for="(option, index) in optionsAction"
                    :key="'optionsMode' + index"
                    v-model="secondary"
                    :aria-describedby="ariaDescribedby"
                    :value="option.value">
        <span v-dompurify-html="option.text" />
      </b-form-radio>
    </b-form-group>
  </span>

</template>
<script>

// only show if the appointment type is 'Estate Planning Consultation' or 'Mortgage Consultation' or 'Insurance Consultation' or 'General Consultation'
// If it is changed it would be Estate Planning Consultation or Mortgage Consultation or Insurance Consultation or General Consultation
// and would change the corresponding role to Estate Planner Full or Protection Advisor or Mortgage Advisor (for General Would be Estate Planner Full)

//If we could mark 'Legal' appointments type and 'Mortgage' appiuntments tyupe and show the bar - but locked?


// optionsEventType: [
//   {
//     label: 'General Consultation',
//     short: 'GC',
//     locked: false
//     code: 'General Consultation'
//   },
//   {
//     label: 'Estate Planning Consultation (& Free Will)',
//     short: 'EPFree',
//     show but locked
//     code: 'Estate Planning Consultation (& Free Will)'
//   },
//   {
//     label: 'Estate Planning Consultation',
//     short: 'EPFF',
//     locked: false
//     code: 'Estate Planning Consultation'
//   },
//   {
//     label: 'Estate Planning Checking',
//     short: 'EPCheck',
//     show but locked
//     code: 'Estate Planning Checking'
//   },
//   {
//     label: 'Estate Planning Final',
//     short: 'EPFinal',
//     show but locked
//     code: 'Estate Planning Final'
//   },
//   {
//     label: 'Mortgage Consultation',
//     short: 'MortFF',
//     locked: false
//     code: 'Mortgage Consultation'
//   },
//   {
//     label: 'Mortgage Sign Up',
//     short: 'MortSup',
//     show but locked
//     code: 'Mortgage Sign Up'
//   },
//   {
//     label: 'Re-Mortgage Consultation',
//     short: 'ReMoFF',
//     show but locked
//     code: 'Re-Mortgage Consultation'
//   },
//   {
//     label: 'Re-Mortgage Sign Up',
//     short: 'ReMoSup',
//     show but locked
//     code: 'Re-Mortgage Sign Up'
//   },
//   {
//     label: 'Insurance Consultation',
//     locked: false
//   },
//   {
//     label: 'Insurance Presentation',
//     show but locked
//   },
//   {
//     label: 'Insurance Review (Existing)',
//     show but locked
//   },
//   {
//     label: 'Estate Planning Review (Existing)',
//     show but locked
//   },
export default {
  name: "meetingType",
  props: ["value"],
  computed:{
    form: {
      set(value) {
        this.$emit('input',
          value);
      },
      get() {
        return this.value;
      }
    },
    show_primary(){
      return this.unlock_on_type.includes(this.value.type);
    },
    show_secondary(){
      return this.lock_on_type.includes(this.value.type);
    },
    secondary(){
      return this.optionsAction.find(x=>x.secondaryValues.includes(this.value.type)).value;
    },
  },
  watch:{
    'form.type':function(val){
      // set corresponding role
      this.$emit('setRole',this.optionsAction.find(x=>x.value===val).role);
    },
  },
  data() {
    return {
      unlock_on_type:['General Consultation','Estate Planning Consultation','Mortgage Consultation','Insurance Consultation'],
      lock_on_type:['Estate Planning Consultation (& Free Will)','Estate Planning Consultation Referral Discounted Services','Estate Planning Checking','Estate Planning Final',
        'Mortgage Sign Up','Re-Mortgage Consultation','Insurance Presentation','Insurance Review (Existing)','Estate Planning Review (Existing)'],
      
      optionsAction: [
        {
          value: 'Estate Planning Consultation',
          role:'Estate Planner Full',
          text: `Legal<br>Advice`,
          secondaryValues:['Estate Planning Consultation (& Free Will)','Estate Planning Consultation Referral Discounted Services','Estate Planning Checking','Estate Planning Final','Estate Planning Review (Existing)'],
        },
        {
          value: 'Mortgage Consultation',
          role: 'Mortgage Advisor',
          text: `Mortgage<br>Advice`,
          secondaryValues:['Mortgage Sign Up','Re-Mortgage Consultation'],
        },
        {
          value: 'Insurance Consultation',
          role: 'Protection Advisor',
          text: `Insurance<br>Advice`,
          secondaryValues:['Insurance Presentation','Insurance Review (Existing)']
        },
        {
          value: 'General Consultation',
          role: 'Estate Planner Full',
          text: `Other<br>Advice`
        }

      ],
    };
  },
};
</script>
