<template>
  <div class="accordion">
    <div>
      <b-card no-body>
        <b-card-header
            class="d-flex justify-content-between"
            header-tag="header"
        >
          <div class="card-heading mb-0">
            <div class="oneTwoThree">2</div>
            Your Details
          </div>
          <div class="text-14 "
               v-if="form.client1.lastName && form.submited"
          >
            {{ form.client1.lastName }}
          </div>
        </b-card-header>
        <b-collapse
            :id="'accordion-form'"
            :visible="form.startDate !== null && !form.submited"
        >
          <b-card-body class="">

            <p v-if="form.type === 'Mortgage Consultation' || form.type === 'Re-Mortgage Consultation'"
               style="font-size: 13px" class="w-100 text-center pb-2">
              Will the mortgage be in a single name or joint names as a couple?
            </p>
            <p v-else style="font-size: 13px" class="w-100 text-center pb-2">
              Are you single or do you have a partner or spouse?
            </p>


            <b-row>
              <b-col md="12">

                <b-form-group
                    v-slot="{ ariaDescribedby }"
                    class="radio-options-buttons wide mb-0 ml-0 m-0 p-0"
                >
                  <b-form-radio
                      v-for="(option, index) in optionsSingleCouple"
                      :key="index"
                      v-model="form.singleCouple"
                      :aria-describedby="ariaDescribedby"
                      :state="errors.singleCouple ? false : null"
                      :value="option.value"
                      class="m-0"
                  ><span>{{ option.text }}</span>
                  </b-form-radio>
                </b-form-group>
                <b-form-invalid-feedback
                    id="type-feedback"
                    :state="errors.errors ? false : null"
                >
                  {{ errors.errors }}
                </b-form-invalid-feedback>
              </b-col>

              <hr
                  v-if="form.singleCouple !== null"
                  class="mb-3"
                  style="margin-top: 30px"
              />

              <b-col v-if="form.singleCouple !== null" md="12">
                <b-row>
                  <b-col md="3" class="mt-2 mb-2">
                    <label>Title</label>
                    <b-select
                        id="client1_title"
                        v-model="form.client1.title"
                        :options="titles"
                        :state="errors.client1.title ? false : null"
                        size="md"
                    ></b-select>
                    <b-form-invalid-feedback
                        id="title1"
                        :state="errors.client1.title ? false : null"
                    >
                      {{ errors.client1.title }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="4" class="mt-2 mb-2">
                    <label>First Name</label>

                    <b-input
                        id="client1_firstName"
                        v-model="form.client1.firstName"
                        :state="errors.client1.firstName ? false : null"
                        placeholder="e.g. Jillian"
                        size="md"
                    />
                    <b-form-invalid-feedback
                        id="firstName1"
                        :state="errors.client1.firstName ? false : null"
                    >
                      {{ errors.client1.firstName }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="5" class="mt-2 mb-2">
                    <label>Last Name</label>

                    <b-input
                        id="client1_firstName"
                        v-model="form.client1.lastName"
                        :state="errors.client1.lastName ? false : null"
                        placeholder="e.g. Bloggs"
                        size="md"
                    />
                    <b-form-invalid-feedback
                        id="lastName1"
                        :state="errors.client1.lastName ? false : null"
                    >
                      {{ errors.client1.lastName }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-2 mb-2">
                    <label>Email</label>
                    <b-input
                        id="client1_email"
                        v-model="form.client1.email"
                        :state="errors.client1.email ? false : null"
                        placeholder="e.g. mail@web.co.uk"
                        size="md"
                        type="email"
                    ></b-input>
                    <b-form-invalid-feedback
                        id="email1"
                        :state="errors.client1.email ? false : null"
                    >
                      {{ errors.client1.email }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="5" class="mt-2 mb-2">
                    <label>Mobile</label>
                    <b-input
                        id="client1_phoneNumber"
                        v-model="form.client1.phoneNumber"
                        :state="errors.client1.phoneNumber ? false : null"
                        placeholder="e.g. 07500112233"
                        size="md"
                    ></b-input>
                    <b-form-invalid-feedback
                        id="phoneNumber1"
                        :state="errors.client1.phoneNumber ? false : null"
                    >
                      {{ errors.client1.phoneNumber }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-col>

              <hr v-if="form.singleCouple" class="mb-0 mt-4"/>

              <transition>
                <b-col
                    v-if="form.singleCouple"
                    class="client2-container mt-4"
                    md="12"
                >
                  <b-row>
                    <b-col md="3" class="mt-2 mb-2">
                      <label>Title</label>
                      <b-select
                          id="client2_title"
                          v-model="form.client2.title"
                          :options="titles"
                          :state="errors.client2.title ? false : null"
                          size="md"
                      ></b-select>
                      <b-form-invalid-feedback
                          id="title2"
                          :state="errors.client2.title ? false : null"
                      >
                        {{ errors.client2.title }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="4" class="mt-2 mb-2">
                      <label>First Name</label>

                      <b-input
                          id="client2_firstName"
                          v-model="form.client2.firstName"
                          :state="errors.client2.firstName ? false : null"
                          placeholder="e.g. Jillian"
                          size="md"
                      />
                      <b-form-invalid-feedback
                          id="firstName2"
                          :state="errors.client2.firstName ? false : null"
                      >
                        {{ errors.client2.firstName }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="5" class="mt-2 mb-2">
                      <label>Last Name</label>

                      <b-input
                          id="client2_firstName"
                          v-model="form.client2.lastName"
                          :state="errors.client2.lastName ? false : null"
                          placeholder="e.g. Bloggs"
                          size="md"
                      />
                      <b-form-invalid-feedback
                          id="lastName2"
                          :state="errors.client2.lastName ? false : null"
                      >
                        {{ errors.client2.lastName }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col class="mt-2 mb-2">
                      <label>Email</label>
                      <b-input
                          id="client2_email"
                          v-model="form.client2.email"
                          :state="errors.client2.email ? false : null"
                          placeholder="e.g. mail@web.co.uk"
                          size="md"
                          type="email"
                      ></b-input>
                      <b-form-invalid-feedback
                          id="email2"
                          :state="errors.client2.email ? false : null"
                      >
                        {{ errors.client2.email }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="5" class="mt-2 mb-2">
                      <label>Mobile</label>
                      <b-input
                          id="client2_phoneNumber"
                          v-model="form.client2.phoneNumber"
                          :state="errors.client2.phoneNumber ? false : null"
                          placeholder="e.g. 07500112233"
                          size="md"
                      ></b-input>
                      <b-form-invalid-feedback
                          id="phoneNumber2"
                          :state="errors.client2.phoneNumber ? false : null"
                      >
                        {{ errors.client2.phoneNumber }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-col>
              </transition>
            </b-row>

            <b-row v-if="form.singleCouple !== null">
              <b-col>
                <label>Notes / Useful Information</label>
                <b-textarea
                    v-model="form.notes"
                    placeholder="e.g. interested in making a will"
                    style="min-height: 108px"
                    debounce="500"
                />
              </b-col>
            </b-row>

            <b-button
                v-if="form.startDate !== null && form.singleCouple !== null"
                class="w-100 mt-5 pt-3 pb-3"
                size="md"
                variant="secondary"
                :disabled="loading"
                @click="$emit('checkForErrors')"
            >Confirm Appointment
              <b-spinner v-if="loading" class="small-spinner align-middle ml-1"/>
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <ModalAdditionalForm v-if="form.additional_form" :additional_form="form.additional_form" @submit="$emit('submit')"/>
  </div>
</template>
<script>
import {clone} from "@/mixins/clone"

import ModalAdditionalForm from "@/components/common/appointment/calender/ModalAdditionalForm";

export default {
  mixins: [clone],
  name: "ClientForm",
  components: {ModalAdditionalForm},
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      optionsSingleCouple: [
        {
          text: "Single",
          value: false,
        },
        {
          text: "Couple",
          value: true,
        },
      ],
      titles: [
        {
          text: "Select",
          value: null,
        },
        "Mr",
        "Mrs",
        "Miss",
        "Master",
        "Ms",
        'Dr'
      ]
    };
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
