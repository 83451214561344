<template>
  <b-row class="booking-form">
    <b-col>
      <b-form

          @reset="onReset">

        <meeting-type
            v-if="role"
            @setRole="setRole"
            v-model="form"/>

        <CalendarMonth
            v-if="!show.error"
            :booking="booking"
            :durationInMinutes="form.duration"
            :inProgress="inProgress"
            :kind="form.type"
            :oneTwoThree="1"
            @currentMonth="currentMonth"
            @nextMonth="nextMonth"
            @prevMonth="prevMonth"
            @setSelected="setSelected"
            ref="calendarMonth"
        />

        <ClientForm
            v-if="showClientForm"
            v-model="form"
            :errors="clientFormErrors"
            :loading="loading"
            @checkForErrors="checkForErrors"
            @submit="onSubmit"
        />

        <MeetingConfirmationPanel
            v-if="show.confirmation && !show.error"
            v-model="form"
            @createIcs="createIcs"
            @navToEvent="navToEvent"
            :step="step"
        />

        <b-alert show variant="danger" v-if="show.error">
          Error Occurred: {{ show.errorMessage }}
        </b-alert>

      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {helpers} from "@/components/common/appointment/calender/helpers";
import {options} from "@/components/common/appointment/calender/options";

import MeetingConfirmationPanel from "./MeetingConfirmationPanel";
import CalendarMonth from "./CalendarMonth";
import MeetingType from "./MeetingType";
import ClientForm from "@/components/common/appointment/calender/ClientForm";
import moment from "moment/moment";

export default {
  name: 'BookingForm',
  components: {
    CalendarMonth,
    MeetingType,
    ClientForm,
    MeetingConfirmationPanel
  },
  mixins: [options, helpers],
  props: {
    transaction: {
      type: Object,
      required: false,
    },
    appointmentType: {
      type: String,
      required: true,
    },
    hostRole: {
      type: String,
      required: true,
    },
    showNewClient: {
      type: Boolean,
      default: false
    },
    showConfirmationPanel: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      required: false,
    },
    accessCode: {
      type: String,
      required: false
    },
    clients: {
      type: Array,
      required: false
    },
    source: {
      type: String,
      required: false
    },
    notes: {
      type: String,
      required: false
    },
    location: {
      // do not provide a default as it will override the server location type
      type: String,
      default: null
    }
  },
  watch: {
    transaction: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) this.setTransaction()
      }
    },
    event: {
      immediate: false,
      deep: true,
      handler() {
        // if event has been updated from elsewhere then re init form
        this.init()
      }
    }
  },
  computed: {
    eventHostRole() {
      const item = this.roleByEventType.find(r => r.types.includes(this.form.type));
      if (item) {
        return item.role;
      }
      return 'Estate Planner Full'
    },
    user() {
      return this.$store.getters.user
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // set appointment type
      this.form.type = this.appointmentType;
      // set source
      this.form.lead_source = this.source
      // set host role
      this.form.role = this.hostRole;
      // set start date to start of the month
      this.startDate = moment(new Date(new Date().getFullYear(),
          new Date().getMonth(),
          1))
      // set pre defined notes
      this.form.notes = this.notes


      if (this.event) {
        console.log('has init event', this.event)
        this.form.eventKey = this.event.eventKey;
        this.form.type = this.event.type;
        this.form.role = this.eventHostRole;
        this.form.startDate = this.event.startDateTime
        // set variables in calendar month to collapse form
        this.$refs.calendarMonth.startTime = new Date(this.event.startDateTime).toLocaleTimeString()
        this.$refs.calendarMonth.startDate = new Date(this.event.startDateTime)

        this.form.startTime = this.event.startDateTime

        this.form.submited = true;
        this.form.hostName = this.event.hostName;

        this.appointmentsRequested.host = this.event.owner.name;
      }
      if (this.event?.clients) {
        this.form.ids = this.event.clients.map((c) => c.id).join(",");
        this.form.names = this.event.clients
            .map((c) => c.profileNameLast)
            .join(",");
      } else if (this.transaction?.clients) {
        this.form.ids = this.transaction.clients.map((c) => c.id).join(",");
        this.form.names = this.transaction.clients
            .map((c) => c.profileNameLast)
            .join(",");
      } else if (this.user?.is_client) {
        this.form.ids = String(this.user.is_client.id)
        this.form.names = this.user.is_client.profileNameLast
      } else if (this.clients?.length) {
        console.log(this.clients)
        // book event for supplied clients prop
        this.form.ids = this.clients.map(c => c.id).join(',')
        this.form.names = this.clients
            .map((c) => c.profileNameLast)
            .join(",");
      }
      // if access code set to form
      // used currently for tracking client person consent referrals from access code
      if (this.accessCode) this.form.access_code = this.accessCode

      this.getSlots()
    },
    clear() {
      this.show.form = true;
      this.show.confirmation = false;
      this.show.error = false
      this.show.errorMessage = null
    },
    rebook() {
      console.log('rebook from booking form')
      this.init()
      this.show.form = true;
      this.show.confirmation = false;
      // set variables in calendar month to collapse form
      this.$refs.calendarMonth.startTime = null
      this.$refs.calendarMonth.startDate = null
    },
    showConfirmation () {
      this.show.confirmation = true;
      this.show.form = false;
    }
  }
}
</script>