<template>
  <div class="calendar-date-selector">
    <button :disabled="inProgress"
            @click="selectPrevious">&lt;
    </button>
    <button :disabled="inProgress"
            @click="selectCurrent(), $emit('currentMonth')">{{ selectedMonth }}
    </button>
    <button :disabled="inProgress"
            @click="selectNext">&gt;
    </button>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarModeSelector",

  props: {
    inProgress: {
      type: Boolean,
      default: true
    },
    currentDate: {
      type: String,
      required: true
    },
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth() {
      return this.selectedDate.format("MMM YY");
    }
  },
  methods: {
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).
        subtract(1,
          "month");
      this.$emit("dateSelected",
        newSelectedDate);
      this.$emit("prevMonth");
    },

    selectCurrent() {
      let newSelectedDate = dayjs(this.currentDate);
      this.$emit("dateSelected",
        newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).
        add(1,
          "month");
      this.$emit("dateSelected",
        newSelectedDate);
      this.$emit("nextMonth");
    }
  }
};
</script>
