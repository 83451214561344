export const options = {
  data () {
    return {
      locationOptions: ['Video Call', 'Telephone', 'Client Home', 'Office', 'Other'],
      roleByEventType: [
        {
          types:['Estate Planning Consultation','Estate Planning Consultation (& Free Will)','Estate Planning Consultation Referral Discounted Services','Estate Planning Checking','Estate Planning Final','Estate Planning Review (Existing)'],
          role:'Estate Planner Full',
        },
        {
          types:['Mortgage Consultation','Mortgage Sign Up','Re-Mortgage Consultation'],
          role: 'Mortgage Advisor',
        },
        {
          types:['Insurance Consultation','Insurance Presentation','Insurance Review (Existing)'],
          role: 'Protection Advisor',
        },
        {
          types: ['General Consultation'],
          role: 'Estate Planner Full',
        }
      ],
    }
  }
}
