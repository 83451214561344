import moment from "moment";

export const linkHelpers = {
  computed: {
    plainuri() {
      let DMURL = process.env.NODE_ENV === "production" ? "https://booking.dunham-mccarthy.co.uk/" : "http://localhost:8080/";
      let url = DMURL + this.formID + "/" + this.form.eventKey;
      if (this.host && this.form.type && this.form.ids && this.form.names) {
        url = (DMURL + this.formID + "/" + this.host.split(" ").join("-") + "/" + this.form.type.split(" ").join("-") + "/" + this.form.ids + "/" + this.form.names.split(" ").join("-") + "/" + this.form.eventKey);
      } else if (this.host && this.form.type) {
        url = (DMURL + this.formID + "/" + this.host.split(' ').join('-') + "/" + this.form.type.split(' ').join('-') + "/" + this.form.eventKey);
      } else if (this.role && this.form.type && this.form.ids && this.form.names) {
        url = (DMURL + this.formID + "/" + this.role.split(" ").join("-") + "/" + this.form.type.split(" ").join("-") + "/" + this.form.ids + "/" + this.form.names.split(" ").join("-") + "/" + this.form.eventKey);
      } else if (this.role && this.form.type) {
        url = (DMURL + this.formID + "/" + this.role.split(' ').join('-') + "/" + this.form.type.split(' ').join('-') + "/" + this.form.eventKey);
      } else if (this.form.lead_source) {
        url = (DMURL + this.formID + "/" + this.form.lead_source + "/" + this.form.eventKey);
      }
      return url;
    },
    uri() {
      return "<a href='" + this.plainuri + "'>Reschedule</a>";
    },
  },
  methods: {
    createIcs() {
      var summary = this.form.type;
      var description = (this.form.description ? this.form.description : '') + this.plainuri;
      var test = "BEGIN:VCALENDAR\n" + "PRODID:Calendar\n" + "VERSION:2.0\n" + "BEGIN:VEVENT\n" + "UID:0@default\n" + "CLASS:PUBLIC\n" + "DESCRIPTION:" + description + "\n" + "DTSTAMP;VALUE=DATE-TIME:20210413T171616\n" + "DTSTART;VALUE=DATE-TIME:" + moment(this.form.startDate + " " + this.form.startTime).format("YYYYMMDDTHHmmss") + "\n" + "DTEND;VALUE=DATE-TIME:" + moment(this.form.startDate + " " + this.form.endTime).format("YYYYMMDDTHHmmss") + "\n" + "LOCATION:Telephone\n" + "SUMMARY;LANGUAGE=en-us:" + summary + "\n" + "TRANSP:TRANSPARENT\n" + "END:VEVENT\n" + "END:VCALENDAR";

      window.open("data:text/calendar;charset=utf8," + escape(test));
    },
  }
}
