<template>
  <div :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar-day--selected': isSelected,
      'calendar-day--past': isPast,
      'available' : !isPast&&(count>=1)&&!isToday&&!isSelected&&day.isCurrentMonth}"
       class="calendar-day"
       @click="$emit('dateSelected')">
    <span>{{ label }}</span>

  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarMonthDayItem",

  props: {
    count: {
      type: Number,
      required: true
    },
    day: {
      type: Object,
      required: true
    },

    isCurrentMonth: {
      type: Boolean,
      default: false
    },

    isToday: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    inPast: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    label() {
      return dayjs(this.day.date).
        format("D");
    },
    isPast() {
      var a = new Date().toJSON().
        slice(0,
          10)
      var b = this.day.date

      if (a > b) {
        return true
      }
      return false
    }
  }
};
</script>

<style scoped>

</style>
